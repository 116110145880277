<template>
    <svg width="301" height="211" viewBox="0 0 301 211" fill="none" xmlns="http://www.w3.org/2000/svg">
<g opacity="0.3" filter="url(#filter0_iiii_755_5909)">
<path d="M56.1124 183.12H79.4004C95.1444 183.12 103.016 175.029 103.016 158.848V52.2479C103.016 36.0666 95.1444 27.9759 79.4004 27.9759H56.1124C40.3684 27.9759 32.4964 36.0666 32.4964 52.2479V158.848C32.4964 175.029 40.3684 183.12 56.1124 183.12ZM83.9924 211H51.1924C35.0111 211 22.4377 206.517 13.4724 197.552C4.50708 188.587 0.0244141 176.123 0.0244141 160.16V50.9359C0.0244141 34.7546 4.50708 22.2906 13.4724 13.5439C22.4377 4.57861 35.0111 0.0959473 51.1924 0.0959473H83.9924C99.9551 0.0959473 112.419 4.57861 121.384 13.5439C130.35 22.2906 134.832 34.7546 134.832 50.9359V160.16C134.832 176.123 130.35 188.587 121.384 197.552C112.419 206.517 99.9551 211 83.9924 211Z" fill="#010013"/>
<path d="M226.777 91.936H244.817C260.999 91.936 269.089 83.9546 269.089 67.9919V50.9359C269.089 35.1919 260.999 27.3199 244.817 27.3199H225.465C217.375 27.3199 211.361 29.1786 207.425 32.8959C203.708 36.6133 201.849 42.6266 201.849 50.9359V60.4479C201.849 64.1653 199.991 66.0239 196.273 66.0239H175.281C171.564 66.0239 169.705 64.1653 169.705 60.4479V50.2799C169.705 34.3173 174.188 21.9626 183.153 13.2159C192.119 4.46928 204.692 0.0959473 220.873 0.0959473H249.409C265.591 0.0959473 278.164 4.46928 287.129 13.2159C296.095 21.9626 300.577 34.3173 300.577 50.2799V68.3199C300.577 81.7763 300.577 128.336 300.577 141.792V160.816C300.577 176.779 296.095 189.133 287.129 197.88C278.164 206.627 265.591 211 249.409 211H220.873C204.692 211 192.119 206.627 183.153 197.88C174.188 189.133 169.705 176.779 169.705 160.816V149.336C169.705 145.4 171.564 143.432 175.281 143.432H196.273C199.991 143.432 201.849 145.4 201.849 149.336V160.16C201.849 168.251 203.708 174.264 207.425 178.2C211.361 181.917 217.375 183.776 225.465 183.776H244.817C260.999 183.776 269.089 175.904 269.089 160.16V142.12C269.089 126.157 260.999 118.176 244.817 118.176H226.777C223.06 118.176 221.201 116.317 221.201 112.6V97.8399C221.201 93.9039 223.06 91.936 226.777 91.936Z" fill="#010013"/>
</g>
<defs>
<filter id="filter0_iiii_755_5909" x="-11.1756" y="-8.81834" width="311.753" height="228.504" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dy="11.6571"/>
<feGaussianBlur stdDeviation="4.34286"/>
<feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
<feColorMatrix type="matrix" values="0 0 0 0 0.494118 0 0 0 0 0.00784314 0 0 0 0 0.960784 0 0 0 1 0"/>
<feBlend mode="normal" in2="shape" result="effect1_innerShadow_755_5909"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dy="1.14286"/>
<feGaussianBlur stdDeviation="2.85714"/>
<feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
<feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 0.976471 0 0 0 0 0.976471 0 0 0 0.88 0"/>
<feBlend mode="normal" in2="effect1_innerShadow_755_5909" result="effect2_innerShadow_755_5909"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dy="1.14286"/>
<feGaussianBlur stdDeviation="0.457143"/>
<feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
<feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.08 0"/>
<feBlend mode="normal" in2="effect2_innerShadow_755_5909" result="effect3_innerShadow_755_5909"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dx="-11.2" dy="-8.91429"/>
<feGaussianBlur stdDeviation="11.6571"/>
<feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
<feColorMatrix type="matrix" values="0 0 0 0 0.752941 0 0 0 0 0.0156863 0 0 0 0 0.996078 0 0 0 0.6 0"/>
<feBlend mode="normal" in2="effect3_innerShadow_755_5909" result="effect4_innerShadow_755_5909"/>
</filter>
</defs>
</svg>

</template>